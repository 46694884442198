/** @format */

import React from "react";
import { useState, useEffect } from "react";
import { FaTelegram, FaInfoCircle } from "react-icons/fa";
import Notiflix from "notiflix";
import axios from "axios";
import { QRCode } from "antd";

const Hero = () => {
  const [bsc, setBsc] = useState("");
  const [tg, setTg] = useState("");
  const [ip, setIp] = useState();
  const [country, setCountry] = useState();
  const [amount, setAmount] = useState(0);
  const [regexError, setRegexError] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const bnbRegex = new RegExp("^(0x)[0-9A-Fa-f]{40}$");

  const [step1, setStep1] = useState(true);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  useEffect(() => {
    axios({
      method: "get",
      url: "https://api.db-ip.com/v2/free/self",
    }).then(res => {
      setIp(res.data.ipAddress);
      setCountry(res.data.countryName);
    });
  }, []);

  function createSale() {
    setBtnLoading(true);
    Notiflix.Loading.standard();
    const data = {
      bsc: bsc,
      tg: tg,
      amount: amount,
      ip: ip,
      country: country,
    };
    console.log(data);
    axios({
      method: "post",
      url: "https://quizbox.herokuapp.com/",
      data,
    })
      .then(res => {
        console.log(res.data);
        setBtnLoading(false);
        setStep1(false);
        setSuccess(true);
        Notiflix.Loading.remove(300);
      })
      .catch(error => {
        setBtnLoading(false);
        setStep1(false);
        setFail(true);
        Notiflix.Loading.remove(500);
      });
  }
  function onAddressChange(address) {
    if (address.length !== 0 && !bnbRegex.test(address)) {
      setRegexError(true);
    } else {
      setBsc(address);
      setRegexError(false);
    }
  }

  return (
    <div className='container-fluid hero-bg mt-3 mb-4'>
      <div className='container'>
          <div className={`box m-auto ${!step1 ? "d-none" : "d-block"}`}>
        <div className='row'>
            <h1>Use Povo Game on your computer</h1>
            <div className='col-lg-7'>
              <ol className="mt-5">
                <li>Open Povo Game on your phone</li>
                <li>Tap Menu or Settings and select Linked Devices</li>
                <li>Tap on Link a device</li>
                <li>Point your phone to this screen to capture the QR code</li>
              </ol>
            </div>
            <div className='col-lg-5 '>
              <QRCode
                errorLevel='H'
                size={300}
                color="#fff"
                value='https://exmaple.com'
                icon='assets/povo.png'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
