/** @format */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import { ConfigProvider } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "react-toastify/dist/ReactToastify.css";
import "notiflix/dist/notiflix-3.2.5.min.css";
import "notiflix/dist/notiflix-3.2.5.min.js";
import Base from "./component/layout/Base";
import Home from "./pages/Home";
import React from "react";
import ReactGA from "react-ga";
ReactGA.initialize("UA-250708013-1");
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ConfigProvider direction='rtl'>
          <Base>
            <Routes>
              <Route index path='/' element={<Home />} />
            </Routes>
          </Base>
        </ConfigProvider>
      </BrowserRouter>

      <ToastContainer position='bottom-right' />
    </React.Fragment>
  );
}

export default App;
