/** @format */

import React from "react";
import Hero from "../component/element/Hero";

const Home = () => {
  return <Hero />;
};

export default Home;
