import React from 'react'
import {
  FaTwitter,
  FaDiscord,
  FaTelegram,
  FaInstagram,
  FaReddit,
  FaFacebook,
  FaGithub,
} from "react-icons/fa";
import { SiMedium } from "react-icons/si";
const Footer = () => {
  return (
    
    <footer className="container-fluid pb-4">
      <div className="row">
       <div className="col-lg-1"></div>
      {/* <div className='col-md-10  page7 m-auto'>
          <a href='https://medium.com/@quizboxfin' target="_blank" rel="noreferrer" >
            <button style={{ color: "#dadee3" }}>
              <SiMedium /> Medium
            </button>
          </a>
          <a href='https://twitter.com/QuizBoxfin' target="_blank" rel="noreferrer" >
            <button style={{ color: "#0ad4fc" }}>
              <FaTwitter /> Twitter
            </button>
          </a>
          <a href='/'>
            <button style={{ color: "#0ad4fc" }} target="_blank" rel="noreferrer" >
              <FaTelegram /> Telegram
            </button>
          </a>
          <a href='https://discord.gg/yqHP5Ayupf' target="_blank" rel="noreferrer" >
            <button  style={{ color: "#5761FC" }}>
              <FaDiscord /> Discord
            </button>
          </a>
          <a href='https://www.instagram.com/quizboxfin/' target="_blank" rel="noreferrer" >
            <button  style={{ color: "#FD096D" }}>
              <FaInstagram /> Instagram
            </button>
          </a>

          <a href='https://github.com/Quizboxfin' target="_blank" rel="noreferrer" >
            <button  style={{ color: "#dadee3" }}>
              <FaGithub /> Github
            </button>
          </a>

          <a href='https://www.reddit.com/r/Quizboxfin/' target="_blank" rel="noreferrer" >
            <button  style={{ color: "#F24100" }}>
              <FaReddit /> Reddit
            </button>
          </a>

          <a href='https://www.facebook.com/QuizBoxFin/' target="_blank" rel="noreferrer" >
            <button  style={{ color: "#1977F3" }}>
              <FaFacebook /> Facebook
            </button>
          </a>

        </div> */}
      </div>
    </footer>
  )
}

export default Footer